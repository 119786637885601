<template>
  <div class="table-simple">
    <div class="title-table">{{ title }}</div>
    <v-simple-table dark class="table-content">
      <template v-slot:default>
        <thead>
          <tr class="header-title">
            <th class="title-screen name-title-screen">Screen</th>
            <th>
              <div class="title-checkbox">
                <v-simple-checkbox
                  dark
                  v-model="viewOnly"
                  @click="handleViewOnlyAllChange"
                ></v-simple-checkbox>
                <div>View - Only</div>
              </div>
            </th>
            <th>
              <div class="title-checkbox">
                <v-simple-checkbox
                  dark
                  v-model="fullControl"
                  @click="handleFullControlAllChange"
                ></v-simple-checkbox>
                <div>Full - Control</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td class="content-screen name-title-screen">
              {{ tranferScreenName(item.name) }}
            </td>
            <td class="content-screen">
              <v-simple-checkbox
                dark
                v-model="item.view"
                :disabled="item.name === 'app_single_site'"
                @click="handleCheckboxChange(item, 'only')"
              ></v-simple-checkbox>
            </td>
            <td class="content-screen">
              <v-simple-checkbox
                dark
                v-model="item.action"
                @click="handleCheckboxChange(item, 'full')"
              ></v-simple-checkbox>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { tranferItem } from '@/utils/common';
export default {
  data() {
    return {
      viewOnly: false,
      fullControl: false,
    };
  },
  watch: {
    items: {
      handler() {
        this.fullControl = this.items.every((item) => item.action);
        this.viewOnly = this.items.every((item) => item.view);
      },
    },
  },
  methods: {
    tranferScreenName(item) {
      return tranferItem(item)
    },
    handleFullControlAllChange() {
      if (this.fullControl) {
        this.items.forEach((item) => {
          item.view = true;
          item.action = true;
          this.viewOnly = true;
        });
      } else {
        this.items.forEach((item) => {
          item.action = this.fullControl;
        });
      }
    },
    handleViewOnlyAllChange() {
      if (!this.viewOnly) {
        this.items.forEach((item) => {
          item.view = item.name !== "app_single_site" ? false : true;
          item.action = false;
          this.fullControl = false;
        });
      } else {
        this.items.forEach((item) => {
          item.view = this.viewOnly;
        });
      }
    },
    handleCheckboxChange(itemCheckbox, property) {
      if (property === 'full' && itemCheckbox.action) {
        itemCheckbox.view = true;
      } else if (property === 'only' && !itemCheckbox.view) {
        itemCheckbox.action = false;
      }
      const checkedAction = this.items.every((item) => item.action);
      const checkedView = this.items.every((item) => item.view);

      this.viewOnly = checkedView;
      this.fullControl = checkedAction;
    },
  },
  props: {
    width: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.table-simple tbody tr:hover {
  background-color: #3A3A3C !important;
}

.table-simple {
  background: #000000;
  padding-top: 32px;
  padding-left: 24px;
}

.table-simple .table-content {
  background: #000000 !important;
}

.table-simple .title-table {
  margin-bottom: 27px;
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.table-simple .table-content .content-screen {
  font-weight: 400;
  color: #ffffff;
  text-align: left !important;
  font-size: 18px !important;
}

.table-simple .table-content .title-screen {
  background-color: #2c2c2e;
  font-size: 18px;
  background-color: #000000;
  font-weight: 400;
  color: #ffffff;
  text-align: left !important;
}

.table-simple .table-content tr > th,
.table-simple .table-content tr > td {
  padding: 0 !important;
}

.table-simple .table-content td {
  font-weight: 400;
}

.name-title-screen {
  width: 60%;
}

.title-checkbox {
  background-color: #2c2c2e;
  font-size: 18px;
  background-color: #000000;
  font-weight: 400;
  color: #ffffff;
  text-align: left !important;
  display: flex;
  align-items: center;
  height: 100%;
}

.theme--dark.v-icon {
  color: #ffffffe5 !important;
  width: 24px;
  height: 24px;
}

::v-deep .v-data-table .v-data-table__wrapper::-webkit-scrollbar {
  width: 10px;
}

.table-simple .v-simple-checkbox.v-simple-checkbox--disabled .v-input--selection-controls__input .v-icon::before {
  opacity: 0.5;
}
@media screen and (max-width: 1440px) {
  .title-checkbox,
  .table-simple .table-content .title-screen,
  .table-simple .table-content .content-screen {
    font-size: 16px !important;
  }
  .name-title-screen {
    width: 40%;
  }
}
</style>
