<template>
  <div class="form-input">
    <div
      v-if="showLabel"
      class="field"
      :class="{ 'field-error-input': errorMessage }"
    >
      <label class="label">{{ fieldLabel }}</label>
      <label v-if="fieldRequired" class="symbol-required">*</label>
    </div>
    <div class="input-form hide-field__details">
      <v-text-field
        v-bind="$attrs"
        v-model="internalValue"
        dense
        solo
        @input="emitValue"
        @keyup.enter="emitEnterEvent"
        :required="required"
        :placeholder="placeholder"
        :height="height"
        :outlined="outlined"
        :prepend-inner-icon="iconInput"
        :class="{
          'out-input-component': outlined,
          'input-component': !outlined,
          'error-input': errorMessage,
        }"
      ></v-text-field>
      <span class="error-message">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    fieldLabel: {
      type: String,
    },
    fieldRequired: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    height: {
      type: Number,
      default: 40,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    iconInput: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  methods: {
    emitValue() {
      this.$emit("input", this.internalValue);
    },
    emitEnterEvent() {
      this.$emit("enter", this.inputValue);
    },
  },
};
</script>

<style lang="scss">
.form-input {
  display: flex;
  align-items: flex-start;
  .field {
    flex: 1;
    display: flex;
    align-items: center;
    height: 40px;
    &.field-error-input {
      height: 44px;
    }
    .label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.10000000149011612px;
      text-align: left;
      margin: 0;
    }
    .symbol-required {
      color: red;
    }
  }

  .input-form {
    flex: 3;
    .input-component {
      border-radius: 8px;
    }

    .out-input-component {
      border-radius: 15px;
    }

    .error-message {
      color: red;
    }

    .error-input {
      border: 2px solid red;
    }
  }
}

.password-random {
  .form-input {
    width: 100%;
    .field {
      margin-right: 54px;
    }
  }
}

.theme--light.v-icon {
  color: #8e8e93 !important;
}

.input-component.theme--light.v-text-field--solo
  > .v-input__control
  > .v-input__slot {
  background: #555 !important;
  &:hover {
    background: #6d6d6d !important;
    cursor: pointer;
  }
}

.out-input-component.theme--light.v-text-field--solo
  > .v-input__control
  > .v-input__slot {
  background: none !important;
  border: 2px solid #636366;
}

.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ffffff !important;
}

.theme--light.v-input input::placeholder {
  color: #9f9f9f !important;
}

.v-input__slot {
  margin-bottom: initial !important;
  input {
    cursor: pointer !important;
  }
}

.v-text-field input {
  line-height: 25px !important;
  font-size: inherit;
  margin-left: 0;
}
</style>
